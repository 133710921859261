
import { Component, Prop, Vue } from "nuxt-property-decorator";
import Icon from "~/components/atoms/embedding/Icon.vue";

@Component({
  components: {
    Icon,
  },
})
export default class CertificateModal extends Vue {
  @Prop({ required: true }) isVisible: boolean;
  @Prop({ required: true }) hasDinPlus: boolean;
  @Prop({ required: true }) hasEnPlus: boolean;
  @Prop({ required: false, default: "" }) dinPlusCertNumber: string | number;
  @Prop({ required: false, default: "" }) enPlusCertNumber: string | number;

  get certificationText() {
    if (this.hasBothCertifications) {
      return "Dieser Händler ist nach ENplus und DINplus zertifiziert, um eine gleichbleibend hohe Qualität zu gewährleisten. Geprüfte Qualität für die gesamte Bereitstellungkette (Herstellung, Lagerung und Lieferlogistik). Die Anforderungen an die Technischen Eigenschaften der Pellets basieren auf der DIN EN ISO 17225-2 Norm, die in einigen wichtigen Eigenschaften noch erweitert wird.";
    } else if (this.hasDinPlus) {
      return "Dieser Händler ist nach DINplus zertifiziert, um eine gleichbleibend hohe Qualität zu gewährleisten. Das DINplus Zertifikat bietet geprüfte Hersteller- und Produktqualität. Die Anforderungen an die Technischen Eigenschaften der Pellets basieren auf der DIN EN ISO 17225-2 Norm, die in einigen wichtigen Eigenschaften noch erweitert wird.";
    }
    return "Dieser Händler ist nach ENplus zertifiziert, um eine gleichbleibend hohe Qualität zu gewährleisten. Geprüfte Qualität für die gesamte Bereitstellungkette (Herstellung, Lagerung und Lieferlogistik). Die Anforderungen an die Technischen Eigenschaften der Pellets basieren auf der DIN EN ISO 17225-2 Norm, die in einigen wichtigen Eigenschaften noch erweitert wird.";
  }

  get hasBothCertifications() {
    return this.hasDinPlus && this.hasEnPlus;
  }

  get modalHeadline() {
    if (this.hasBothCertifications) {
      return "DINplus und ENplus zertifiziert";
    } else if (this.hasDinPlus) {
      return "DINplus zertifiziert";
    }
    return "ENplus zertifiziert";
  }

  close() {
    this.$emit("close");
  }
}
